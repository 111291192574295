@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.Photo {

	&__square {
		width: 47px;
		height: 47px;
		float: left;
	}

	&__link {
		color: inherit;
	}

	&__entry:target p {
		background-color: #ececec;
	}

	&__bar {
		width:100%;
		height:50px;

		margin: 15px 0;

		&--main {
			margin: 0 0 30px 0;
		}
	}

	&__quote {
		text-align: right;

		& + a > .Photo__bar {
			margin-top: 0;
		}
	}
}

.Story {
	margin: 15px 0;
	font-size: 150%;
	line-height: 1.4;

	p {
		font-weight: 300;
	}

	&__photorow {
		margin-bottom: 18px;
	}

	&__entry {
		margin-bottom: 24px;
	}
}

.Filter {

}

.form-control-static#photo {
	display: block;
	float: none;
}